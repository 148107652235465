
export type Color = (
  | 'normal'
  | 'mono'
  | 'bold'
  | 'amber'
  | 'blue'
  | 'green'
  | 'cyan'
  | 'orange'
  | 'red'
);

type ColorFn = (x: string) => string;

type ConsoleWrappingFn = (...z: any[]) => void;

function wrap(x: string): string { return `%c${x}%c`; }

export class FancyLogger {
  public static normal = `color: revert; background: revert; font-weight: revert; font-family: revert; font-size: revert;`;

  public static mono = `font-family: monospace;`;

  public static bold = `font-weight: bold;`;

  private static chip = `border-radius: 2px; padding: 2px 6px; width: 100px;${FancyLogger.mono}${FancyLogger.bold}`;

  public static amber = `color: #FF6F00; background: #FFECB3;${FancyLogger.chip}`;

  public static blue = `color: #0D47A1; background: #BBDEFB;${FancyLogger.chip}`;

  public static green = `color: #4CAF50; background: #003346;${FancyLogger.chip}`;

  public static cyan = `color: #01579B; background: #B3E5FC;${FancyLogger.chip}`;

  public static orange = `color: #E65100; background: #FFE0B2;${FancyLogger.chip}`;

  public static red = `color: #EB3D46; background: #650000;${FancyLogger.chip}`;

  formats: string[] = null;

  @color public normal: ColorFn;

  @color public amber: ColorFn;

  @color public blue: ColorFn;

  @color public green: ColorFn;

  @color public cyan: ColorFn;

  @color public orange: ColorFn;

  @color public red: ColorFn;

  @color public bold: ColorFn;

  @color public mono: ColorFn;

  @formatted public log: ConsoleWrappingFn;

  @formatted public error: ConsoleWrappingFn;

  @formatted public debug: ConsoleWrappingFn;

  @formatted public warn: ConsoleWrappingFn;

  @formatted public info: ConsoleWrappingFn;

  @formatted public group: ConsoleWrappingFn;

  @formatted public groupEnd: ConsoleWrappingFn;
}


function color(target: FancyLogger, key: Color) {
  target[key] = function(string: string): string {
    target.formats ??= [];
    target.formats.push(FancyLogger[key], FancyLogger.normal);
    return wrap(string);
  };
}

function formatted(target: FancyLogger, key: keyof Console) {
  target[key] = function(...args: any[]): void {
    target.formats ??= [];
    // eslint-disable-next-line no-console
    (console[key] as any)(...args, ...target.formats);
    target.formats = null;
  };
}
