/** start a performance trace */
export function start(): number {
  if (!window.performance)
    return;
  const timestamp = performance.now();
  performance.mark(`${timestamp}-start`);
  return timestamp;
}

/** end and take a performance trace */
export function end(timestamp: number): number {
  if (!window.performance)
    return;
  performance.mark(`${timestamp}-end`);
  performance.measure(`${timestamp}-measure`, `${timestamp}-start`, `${timestamp}-end`);
  const [{ duration = undefined }] = performance.getEntriesByName(`${timestamp}-measure`) ?? [{}];
  return duration;
}
