import { Constructor } from '@apollo-elements/interfaces';
import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { isString } from '#lib/isString';
import { Color, FancyLogger } from './fancy-logger';
import { end, start } from './tracing';

type TraceFn = (...args: any[]) => void;

type ConsoleMethod = (
  | 'info'
  | 'error'
  | 'warn'
  | 'debug'
);

interface LogDecoratorOptions {
  /** Color for the method name or trace description. */
  color?: Color
  /** Logger prefix (default: 'LOG'). */
  prefix?: string;
  /** Whether to trace performance. If a string, the description of the trace. */
  trace?: boolean|string;
}

interface LogItOptions {
  color: Color;
  name: string;
  prefix: string;
  tagName?: string;
  time?: number;
}

const CONSOLE_KEYS: Record<SeverityLevel, ConsoleMethod> = {
  [SeverityLevel.Information]: 'info',
  [SeverityLevel.Error]: 'error',
  [SeverityLevel.Critical]: 'error',
  [SeverityLevel.Warning]: 'warn',
  [SeverityLevel.Verbose]: 'debug',
};

export class Logger extends FancyLogger {
  private static singleton = new Logger();

  // Build steps will replace this string with an env var
  private static instrumentationKey = 'APPINSIGHTS_INSTRUMENTATIONKEY';

  public static sendLogsToAppInsights = Logger.instrumentationKey !== `APPINSIGHTS_${'INSTRUMENTATIONKEY'}`;

  private static groups: string[] = [];

  private static appInsights = Logger.sendLogsToAppInsights && new ApplicationInsights({
    config: {
      instrumentationKey: Logger.instrumentationKey,
      enableAutoRouteTracking: true,
      disableFetchTracking: false,
      isCookieUseDisabled: true,
    },
  });

  static trackTrace(severityLevel: SeverityLevel, ...args: any[]): void {
    if (Logger.sendLogsToAppInsights) {
      const message = (args.length > 1) ? args.map(x => `${x}`).join('\n') : args[0];
      Logger.appInsights.trackTrace({ message, severityLevel });
    }

    if (/dev|test/.test('GRAPHQL_HOST'))
      Logger.prototype[CONSOLE_KEYS[severityLevel]].call(this, ...args); // eslint-disable-line no-console
  }

  constructor() {
    super();
    return Logger.singleton;
  }

  public static init(): void {
    if (Logger.sendLogsToAppInsights) {
      Logger.appInsights.loadAppInsights();
      // Manually call trackPageView to establish the current user/session/pageview
      Logger.appInsights.trackPageView();
    }
  }

  @trace(SeverityLevel.Information) log: TraceFn;

  @trace(SeverityLevel.Error) error: TraceFn;

  @trace(SeverityLevel.Verbose) debug: TraceFn;

  @trace(SeverityLevel.Warning) warn: TraceFn;

  @trace(SeverityLevel.Information) info: TraceFn;

  group: TraceFn = (...args) => {
    const [tag] = args;
    Logger.trackTrace(SeverityLevel.Information, `START: ${tag}`);
    Logger.groups.push(tag);
  };

  groupEnd = (): void =>
    Logger.trackTrace(SeverityLevel.Information, `END: ${Logger.groups.pop()}`);
}

function logIt({ color, name, prefix, tagName, time }: LogItOptions) {
  const { blue, bold } = logger;
  const col = logger[color];
  const tagString = () => tagName ? ` ${bold(tagName)}` : '';
  const timeString = () => time ? ` took ${bold(time.toString())} ms` : '';
  logger.info(`${blue(prefix.toUpperCase())} ${col(name)}${tagString()}${timeString()}`);
}

function trace(level: SeverityLevel): PropertyDecorator {
  return function(target: Logger, key: ConsoleMethod) {
    target[key] = function(...args: any[]) {
      Logger.trackTrace(level, ...args);
    };
  };
}


/**
 * Fancy logging decorator for class methods
 */
export function log(options?: LogDecoratorOptions): MethodDecorator {
  const { color = 'blue', prefix = 'log', trace } = options;

  return function log(target: unknown, key: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;

    const name = isString(trace) ? trace : key;

    if (typeof method !== 'function')
      throw new TypeError(`@log decorator can only be applied to methods, not: ${typeof method}`);

    if (method.constructor.name === 'AsyncFunction') {
      descriptor.value = async function(...args: any[]) {
        const ts = trace && start();
        const result = await method.call(this, ...args);
        const time = trace && end(ts);
        const tagName = this.localName;
        logIt({ color, name, prefix, tagName, time });
        return result;
      };
    } else {
      descriptor.value = function(...args: any[]) {
        const ts = trace && start();
        const result = method.call(this, ...args);
        const time = trace && end(ts);
        const tagName = this.localName;
        logIt({ color, name, prefix, tagName, time });
        return result;
      };
    }
  };
}

if (Logger.sendLogsToAppInsights)
  Logger.init();

export const logger = Logger.sendLogsToAppInsights ? new Logger() : new FancyLogger();

export const LoggerMixin =
  dedupeMixin(function LoggerMixinImpl<C extends Constructor>(superclass: C) {
    class LoggedElement extends superclass {
      @log({ color: 'green', prefix: 'lifecycle' })
      connectedCallback(): void {
        super.connectedCallback?.();
      }

      @log({ color: 'amber', prefix: 'lifecycle' })
      disconnectedCallback(): void {
        super.disconnectedCallback?.();
      }
    }

    return LoggedElement;
  });
